<template>
  <ResendInvite
    :model-value="modelValue"
    email-type="confirm"
    @change-type="emit('change-type', $event)"
  />
</template>
<script setup lang="ts">
import { ResendInvite } from '~/pages/auth/login/_components/resend-invite';
import { SelfRegisterType } from '~/api/auth';

type Props = {
  modelValue: string;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  'change-type': [value: SelfRegisterType];
}>();
</script>
